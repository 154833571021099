'use client';

import { FullScreenDialog } from '@frontend/components/FullScreenDialog';
import AppContainer from '@frontend/components/ui/AppContainer';
import UserSettingsSection from '@frontend/components/UserSettingsSection';
import WalletConnectSection from '@frontend/components/WalletConnectSection';
import logoDark from '@frontend/public/assets/images/logo.svg';
import { cn } from '@frontend/utils/tailwindUtil';
import { GearIcon } from '@radix-ui/react-icons';
import {
  AspectRatio,
  Badge,
  Button,
  Flex,
  IconButton,
  Separator,
  Skeleton,
  Text,
} from '@radix-ui/themes';
import { Scanner } from '@yudiel/react-qr-scanner';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { useCallback, useEffect, useState } from 'react';
import { MdQrCode } from 'react-icons/md';
import { useAccount } from 'wagmi';

export function Header() {
  const router = useRouter();
  const { isConnected } = useAccount();
  const [isQrAccessLoading, setIsQrAccessLoading] = useState(false);
  const [qrCodeError, setQrCodeError] = useState<string | null>(null);

  const [dialogState, setDialogState] = useState({
    settings: false,
    wallet: false,
    scanQRCode: false,
  });

  const toggleDialog = (dialogType: 'settings' | 'wallet' | 'scanQRCode') => {
    setDialogState((prev) => ({
      ...prev,
      [dialogType]: !prev[dialogType],
    }));
  };

  // If user logs out, we should reset the dialog state
  useEffect(() => {
    setDialogState({
      settings: false,
      wallet: false,
      scanQRCode: false,
    });
  }, [isConnected]);

  // Check if the user is allowed site for camera access
  const [isAllowedToScan, setIsAllowedToScan] = useState(false);

  const requestCameraAccess = useCallback(async () => {
    setIsQrAccessLoading(true);
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: { exact: 'environment' },
        },
      });

      // Stop the stream immediately as we just need to check access
      stream.getTracks().forEach((track) => track.stop());

      setIsAllowedToScan(true);
    } catch (error) {
      console.error('Camera access error:', error);
      setIsAllowedToScan(false);
    }
    setIsQrAccessLoading(false);
  }, []);

  useEffect(() => {
    if (dialogState.scanQRCode) {
      requestCameraAccess();
    } else {
      // Reset camera access when dialog is closed
      setIsAllowedToScan(false);
    }
  }, [dialogState.scanQRCode, requestCameraAccess]);

  return (
    <>
      <Flex
        px="2"
        py="3"
        justify="between"
        align="center"
        className={cn('shadow-sm')}
        style={{ background: 'var(--accent-0' }}
      >
        <Flex gap="1" align="center">
          <Link href="/go" passHref>
            <Image src={logoDark} alt="Yodl Logo" width={70} priority />
          </Link>
          <Badge variant="soft" size="1">
            beta
          </Badge>
          <IconButton
            ml="3"
            variant="ghost"
            onClick={() => toggleDialog('scanQRCode')}
            className="lg:!hidden"
          >
            <MdQrCode className="size-5" />
          </IconButton>
        </Flex>

        {isConnected ? (
          <IconButton
            variant="ghost"
            className="!m-0"
            size="2"
            onClick={() => toggleDialog('settings')}
          >
            <GearIcon className="size-5" />
          </IconButton>
        ) : (
          <Button
            variant="outline"
            size="3"
            onClick={() => toggleDialog('wallet')}
          >
            Connect
          </Button>
        )}
      </Flex>

      <Separator orientation="horizontal" size="4" />

      <FullScreenDialog
        open={dialogState.wallet}
        addDesktopPadding
        onOpenChange={(open) =>
          setDialogState((prev) => ({ ...prev, wallet: open }))
        }
        title="Connect Wallet"
      >
        <WalletConnectSection />
      </FullScreenDialog>

      <FullScreenDialog
        open={dialogState.settings}
        addDesktopPadding
        onOpenChange={(open) =>
          setDialogState((prev) => ({ ...prev, settings: open }))
        }
        title={isConnected ? 'Settings' : 'Payment Preferences'}
      >
        <UserSettingsSection
          handleOnClose={() => {
            setDialogState((prev) => ({ ...prev, settings: false }));
          }}
        />
      </FullScreenDialog>

      <FullScreenDialog
        open={dialogState.scanQRCode}
        addDesktopPadding
        onOpenChange={(open) => {
          setDialogState((prev) => ({ ...prev, scanQRCode: open }));
        }}
        title="Scan QR Code"
      >
        <Flex mt="9" direction="column" gap="4" align="center">
          <AspectRatio ratio={1} className="rounded-3xl overflow-hidden">
            {isAllowedToScan ? (
              <Scanner
                onScan={(result) => {
                  if (Array.isArray(result) && result.length > 0) {
                    const url = result[0].rawValue;
                    setQrCodeError(null);
                    if (url.startsWith('https://')) {
                      const currentOrigin = window.location.origin;
                      const scannedOrigin = new URL(url).origin;

                      if (scannedOrigin === currentOrigin) {
                        router.push(url);
                        toggleDialog('scanQRCode'); // Close the dialog after successful scan
                      } else {
                        setQrCodeError(
                          'Scanned QR code is not from the current origin',
                        );
                      }
                    } else {
                      setQrCodeError('Invalid or unsafe URL detected');
                    }
                  }
                }}
                onError={(error) => {
                  if (error instanceof Error) {
                    setQrCodeError(error.message);
                  } else {
                    setQrCodeError('An unknown error occurred');
                  }
                  setIsQrAccessLoading(false);
                  setIsAllowedToScan(false);
                }}
                constraints={{
                  facingMode: { exact: 'environment' },
                }}
              />
            ) : (
              <Flex justify="center" align="center" className="w-full h-full">
                <Skeleton className="w-3/4 h-3/4" />
              </Flex>
            )}
          </AspectRatio>

          {!isAllowedToScan ? (
            <Button
              radius="full"
              loading={isQrAccessLoading}
              onClick={requestCameraAccess}
            >
              Request Camera Access
            </Button>
          ) : (
            <Text>Scan to send crypto</Text>
          )}

          {qrCodeError && <Text color="red">{qrCodeError}</Text>}
        </Flex>
      </FullScreenDialog>
    </>
  );
}
