'use client';

import { PaymentPreferencesSection } from '@frontend/app/shared/layout/PaymentPreferencesSection';
import useAccountEns from '@frontend/client/hooks/useAccountEns';
import { truncateAddress } from '@frontend/common';
import UserAvatar from '@frontend/components/UserAvatar';
import { chainById } from '@frontend/utils/chains';
import { ChevronRightIcon, ExternalLinkIcon } from '@radix-ui/react-icons';
import { Card, Flex, Grid, Select, Spinner, Text } from '@radix-ui/themes';
import { useAccountModal, useChainModal } from '@rainbow-me/rainbowkit';
import Image from 'next/image';
import Link from 'next/link';
import { FC, useState } from 'react';
import { useAccount } from 'wagmi';

type Sections = 'main' | 'paymentPreferences';

interface UserSettingsSectionProps {
  handleOnClose: () => void;
}

const UserSettingsSection: FC<UserSettingsSectionProps> = ({
  handleOnClose,
}) => {
  const { address } = useAccount();
  const { openAccountModal } = useAccountModal();
  const { data } = useAccountEns();

  const userEns = data?.ens;

  const [currentSection, setCurrentSection] = useState<Sections>('main');
  return (
    <Grid gap="3">
      {currentSection === 'main' && (
        <>
          <Card
            hidden={currentSection !== 'main'}
            variant="classic"
            size="2"
            className="cursor-pointer"
            onClick={() => {
              if (openAccountModal) {
                openAccountModal();
                setTimeout(() => (document.body.style.pointerEvents = ''), 0);
              }
            }}
          >
            <Flex justify="between" align="center">
              <Flex gap="3" align="center">
                <UserAvatar ethAddress={address} />
                <Flex direction="column">
                  {(address || userEns) && (
                    <Text size="2" weight="bold">
                      {userEns || truncateAddress(address || '', 4)}
                    </Text>
                  )}
                  {userEns && (
                    <Text size="1" color="gray">
                      {truncateAddress(address || '', 4)}
                    </Text>
                  )}
                </Flex>
              </Flex>
            </Flex>
          </Card>

          <Flex direction="column" gap="2" mt="4">
            {userEns && (
              <Link
                href={`https://app.justaname.id/community/${userEns}?tab=POAP`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Card variant="classic" size="2" className="cursor-pointer">
                  <Flex justify="between" align="center">
                    <div>
                      <Text size="2" weight="bold" as="p">
                        Profile
                      </Text>
                      <Text color="gray" size="1" as="p">
                        Manage your ENS profile
                      </Text>
                    </div>
                    <ExternalLinkIcon />
                  </Flex>
                </Card>
              </Link>
            )}

            <Card
              className="cursor-pointer"
              variant="classic"
              size="2"
              onClick={() => {
                setCurrentSection('paymentPreferences');
              }}
            >
              <Flex justify="between" align="center">
                <div>
                  <Text size="2" weight="bold" as="p">
                    Payment Setup
                  </Text>
                  <Text color="gray" size="1" as="p">
                    Set receiving tokens and chains
                  </Text>
                </div>
                <ChevronRightIcon />
              </Flex>
            </Card>
          </Flex>
        </>
      )}

      {/* Sections */}
      {currentSection === 'paymentPreferences' && (
        <PaymentPreferencesSection handleOnClose={handleOnClose} />
      )}
    </Grid>
  );
};

export default UserSettingsSection;

// not used currently
const ChainSelectBox = () => {
  const { address, chainId } = useAccount();
  const { openChainModal } = useChainModal();
  const { openAccountModal } = useAccountModal();
  const { chainInfo } = chainById(chainId || 1);

  return (
    <Select.Root size="2" value="light">
      <Select.Trigger
        radius="full"
        variant="soft"
        className="!m-0"
        onClick={(event) => {
          if (openChainModal) {
            event.stopPropagation();
            event.preventDefault();
            openChainModal();
            // Hack to access modal inside the other modal - https://github.com/radix-ui/primitives/issues/2122
            setTimeout(() => (document.body.style.pointerEvents = ''), 0);
          }
        }}
      >
        <Flex as="span" align="center" gap="2">
          {chainInfo?.logoUri ? (
            <Image
              src={chainInfo?.logoUri}
              alt="Chain Logo"
              width={24}
              height={24}
            />
          ) : (
            <Spinner size="2" />
          )}
        </Flex>
      </Select.Trigger>
    </Select.Root>
  );
};
