// Get latest batch through: curl yodl.me/api/healthcheck/unstoppable
export const UNSTOPPABLE_TLDS = [
  'crypto',
  'bitcoin',
  'blockchain',
  'unstoppable',
  'dao',
  'nft',
  '888',
  'wallet',
  'x',
  'klever',
  'hi',
  'kresus',
  'polygon',
  'anime',
  'manga',
  'binanceus',
  'go',
  'altimist',
  'pudgy',
  'austin',
  'bitget',
  'pog',
  'clay',
  'witg',
  'metropolis',
  'wrkx',
  'secret',
  'zil',
];

export const FREENAME_TLDS = [
  'hodl',
  'metaverse',
  'token',
  'satoshi',
  'moon',
  'airdrop',
];

export const BOX_TLDS = ['box'];
