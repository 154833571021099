import React, { FC, useState } from 'react';
import { useConnect } from 'wagmi';
import { coinbaseWallet } from 'wagmi/connectors';
import { Button, Flex, Grid, Text } from '@radix-ui/themes';
import Image from 'next/image';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import {
  WalletCoinbase,
  WalletPhantom,
  WalletWalletConnect,
} from '@web3icons/react';
import { isEmpty } from 'lodash';

interface WalletConnectSectionProps {
  isReceiveContent?: boolean;
}

const WalletConnectSection: FC<WalletConnectSectionProps> = ({
  isReceiveContent = false,
}) => {
  const { connectors, connectAsync } = useConnect();
  const [pendingConnector, setPendingConnector] = useState<string | null>(null);

  const injectedConnectors = connectors
    .filter((c) => c.type === 'injected' && c.isAuthorized)
    .reduce(
      (unique, connector) => {
        const existing = unique.find((c) => c.name === connector.name);
        if (!existing || (!existing.icon && connector.icon)) {
          // Remove existing if new one has icon
          if (existing) {
            unique = unique.filter((c) => c.name !== existing.name);
          }
          unique.push(connector);
        }
        return unique;
      },
      [] as (typeof connectors)[number][],
    );

  const openInPhantom = () => {
    const url = phantomURL();
    open(url);
  };

  function phantomURL() {
    const url = encodeURIComponent(window.location.href);
    const ref = encodeURIComponent('https://yodl.me');
    return `https://phantom.app/ul/browse/${url}?ref=${ref}`;
  }

  const connectSmartWallet = () => {
    setPendingConnector('coinbase');
    connectAsync({
      connector: coinbaseWallet({
        appName: 'yodl',
        preference: 'smartWalletOnly',
      }),
    }).finally(() => setPendingConnector(null));
  };

  return (
    <Grid pb="6">
      <Grid gap="5">
        <div>
          <Flex py="2">
            <Text size="1" mr="1" weight="medium">
              Smart Wallets:
            </Text>
            <Text size="1" color="gray">
              gas free, best UX, 30s setup
            </Text>
          </Flex>

          <Button
            className="!py-7 pl-2 shadow-lg"
            size="3"
            style={{ width: '100%' }}
            radius="large"
            color="gray"
            variant="outline"
            onClick={connectSmartWallet}
            loading={pendingConnector === 'coinbase'}
            disabled={
              pendingConnector !== null && pendingConnector !== 'coinbase'
            }
          >
            <Flex align="center" gap="2" justify="start" className="w-full">
              <WalletCoinbase
                size={32}
                variant="branded"
                style={{ color: 'var(--accent-12' }}
              />

              <Text size="3" weight="medium">
                Smart Wallet
                <Text size="1" weight="medium" ml="1">
                  by Coinbase
                </Text>
              </Text>
            </Flex>
          </Button>
        </div>

        <Grid gap="2">
          <Text size="1" className="max-w-xs py-1" weight="medium">
            Existing Wallets
          </Text>

          <ConnectButton.Custom>
            {({ openConnectModal }) => {
              return (
                <Button
                  className="!py-7 pl-2 shadow-lg"
                  size="3"
                  style={{ width: '100%' }}
                  radius="large"
                  color="gray"
                  variant="outline"
                  loading={pendingConnector === 'walletconnect'}
                  disabled={
                    pendingConnector !== null &&
                    pendingConnector !== 'walletconnect'
                  }
                  onClick={() => {
                    setPendingConnector('walletconnect');
                    openConnectModal();
                  }}
                >
                  <Flex
                    align="center"
                    gap="2"
                    justify="start"
                    className="w-full"
                  >
                    <WalletWalletConnect
                      style={{ color: 'var(--accent-12' }}
                      size={32}
                      variant="mono"
                    />
                    <Text size="3" weight="medium">
                      Wallet Connect
                    </Text>
                  </Flex>
                </Button>
              );
            }}
          </ConnectButton.Custom>
        </Grid>

        <Grid gap="2" className="sm:!hidden">
          <Text size="1" className="max-w-xs py-1">
            Open in Wallet
          </Text>

          <Button
            className="!py-7 pl-2"
            size="3"
            style={{ width: '100%' }}
            variant="outline"
            onClick={() => {
              setPendingConnector('phantom');
              openInPhantom();
            }}
          >
            <Flex align="center" gap="2" justify="start" className="w-full">
              <WalletPhantom
                style={{ color: 'var(--accent-12' }}
                size={32}
                variant="mono"
              />
              <Text size="3" weight="medium">
                Phantom
              </Text>
            </Flex>
          </Button>
        </Grid>

        {!isEmpty(injectedConnectors) && (
          <Grid gap="2">
            <Text size="1" className="max-w-xs py-1">
              Open in browser wallet
            </Text>

            {injectedConnectors.map((c) => (
              <Button
                className="!py-7 pl-2"
                size="3"
                style={{ width: '100%' }}
                variant="outline"
                key={c.id}
                loading={pendingConnector === c.id}
                disabled={
                  pendingConnector !== null && pendingConnector !== c.id
                }
                onClick={() => {
                  setPendingConnector(c.id);
                  connectAsync({ connector: c }).finally(() =>
                    setPendingConnector(null),
                  );
                }}
              >
                <Flex align="center" gap="2" justify="start" className="w-full">
                  {c.icon && (
                    <Image src={c.icon} alt={c.name} width={32} height={32} />
                  )}
                  <Text size="3" weight="medium">
                    {c.name}
                  </Text>
                </Flex>
              </Button>
            ))}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default WalletConnectSection;
