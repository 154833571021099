import { Identity } from '@frontend/utils/identity';
import { cn } from '@frontend/utils/tailwindUtil';
import { PersonIcon } from '@radix-ui/react-icons';
import { Avatar } from '@radix-ui/themes';
import React, { ReactElement } from 'react';
import { normalize } from 'viem/ens';
import { useEnsAvatar } from 'wagmi';

type Size = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9';

interface Props {
  ensAddress?: string;
  ethAddress?: string;
  fallbackIcon?: ReactElement;
  size?: Size;
}

const sizeMap: Record<Size, number> = {
  '1': 16,
  '2': 32,
  '3': 48,
  '4': 64,
  '5': 80,
  '6': 96,
  '7': 128,
  '8': 160,
  '9': 192,
};

const UserAvatar: React.FC<Props> = ({
  ensAddress,
  fallbackIcon,
  ethAddress,
  size = '2',
}) => {
  const isIdentityTypeEns = React.useMemo(() => {
    return Identity.parseType(ensAddress) === 'ens';
  }, [ensAddress]);

  const { data, isSuccess } = useEnsAvatar({
    name: isIdentityTypeEns && ensAddress ? normalize(ensAddress) : ensAddress,
    query: { enabled: isIdentityTypeEns },
  });

  const avatarUrl = React.useMemo(() => {
    if (data && isSuccess) {
      return data as string;
    }
    //   Get the avatar URL from eth address
    if (ethAddress) {
      return `https://effigy.im/a/${ethAddress}.svg`;
    }
  }, [data, ethAddress, isSuccess]);

  const Fallback = fallbackIcon || <PersonIcon className={cn('size-5')} />;

  const avatarSize = sizeMap[size];

  return (
    <Avatar
      src={avatarUrl}
      fallback={Fallback}
      radius="full"
      size={size}
      style={{ width: avatarSize, height: avatarSize }}
    />
  );
};

export default UserAvatar;
